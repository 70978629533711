
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { User } from "@/models/users";
  import { SingleResourceDoc } from '@/models/jsonapi'

  interface Props {
    id: string
  }

  export default defineComponent({
    props: {
      id: {
        type: String,
        required: true
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy

      const dbData = computed<SingleResourceDoc<string, User>>(() => root.$store.state.users.element)

      root.$store.dispatch('users/get', { id: props.id })

      return {
        dbData
      }
    }
  })
